export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/",
  },
  {
    id: 2,
    label: "menuitems.uielements.text",
    icon: "ri-pencil-ruler-2-line",
    subItems: [
      {
        id: 3,
        label: "menuitems.uielements.list.alerts",
        link: "",
      },
      {
        id: 4,
        label: "menuitems.uielements.list.buttons",
        link: "",
      },
      {
        id: 5,
        label: "menuitems.uielements.list.cards",
        link: "",
      },
      {
        id: 6,
        label: "menuitems.uielements.list.carousel",
        link: "",
      },
      {
        id: 7,
        label: "menuitems.uielements.list.dropdowns",
        link: "",
      },
      {
        id: 8,
        label: "menuitems.uielements.list.grid",
        link: "",
      },
      {
        id: 9,
        label: "menuitems.uielements.list.images",
        link: "",
      },
      {
        id: 10,
        label: "menuitems.uielements.list.lightbox",
        link: "",
      },
      {
        id: 11,
        label: "menuitems.uielements.list.modals",
        link: "",
      },
      {
        id: 12,
        label: "menuitems.uielements.list.rangeslider",
        link: "",
      },
      {
        id: 13,
        label: "menuitems.uielements.list.sessiontimeout",
        link: "",
      },
      {
        id: 14,
        label: "menuitems.uielements.list.progressbar",
        link: "",
      },
      {
        id: 15,
        label: "menuitems.uielements.list.sweetalert",
        link: "",
      },
      {
        id: 16,
        label: "menuitems.uielements.list.tabs",
        link: "",
      },
      {
        id: 17,
        label: "menuitems.uielements.list.typography",
        link: "",
      },
      {
        id: 18,
        label: "menuitems.uielements.list.video",
        link: "",
      },
      {
        id: 19,
        label: "menuitems.uielements.list.general",
        link: "",
      },
      {
        id: 20,
        label: "menuitems.uielements.list.rating",
        link: "",
      },
      {
        id: 21,
        label: "menuitems.uielements.list.notifications",
        link: "",
      },
    ],
  },
  {
    id: 22,
    label: "menuitems.apps.text",
    icon: "ri-apps-2-line",
    subItems: [
      {
        id: 23,
        label: "menuitems.calendar.text",
        link: "",
      },
      {
        id: 24,
        label: "menuitems.chat.text",
        link: "",
      },
      {
        id: 25,
        label: "menuitems.email.text",
        subItems: [
          {
            id: 26,
            label: "menuitems.email.list.inbox",
            link: "",
          },
          {
            id: 27,
            label: "menuitems.email.list.reademail",
            link: "",
          },
        ],
      },
      {
        id: 28,
        label: "menuitems.ecommerce.text",
        subItems: [
          {
            id: 29,
            label: "menuitems.ecommerce.list.products",
            link: "",
          },
          {
            id: 30,
            label: "menuitems.ecommerce.list.productdetail",
            link: "",
          },
          {
            id: 31,
            label: "menuitems.ecommerce.list.orders",
            link: "",
          },
          {
            id: 32,
            label: "menuitems.ecommerce.list.customers",
            link: "",
          },
          {
            id: 33,
            label: "menuitems.ecommerce.list.cart",
            link: "",
          },
          {
            id: 34,
            label: "menuitems.ecommerce.list.checkout",
            link: "",
          },
          {
            id: 35,
            label: "menuitems.ecommerce.list.shops",
            link: "",
          },
          {
            id: 36,
            label: "menuitems.ecommerce.list.addproduct",
            link: "",
          },
        ],
      },
      {
        id: 37,
        label: "menuitems.kanban.text",
        link: "",
      },
    ],
  },
  {
    id: 37,
    label: "menuitems.components.text",
    icon: "ri-stack-line",
    subItems: [
      {
        id: 38,
        label: "menuitems.forms.text",
        subItems: [
          {
            id: 39,
            label: "menuitems.forms.list.elements",
            link: "",
          },
          {
            id: 40,
            label: "menuitems.forms.list.validation",
            link: "",
          },
          {
            id: 41,
            label: "menuitems.forms.list.advanced",
            link: "",
          },
          {
            id: 42,
            label: "menuitems.forms.list.editor",
            link: "",
          },
          {
            id: 43,
            label: "menuitems.forms.list.fileupload",
            link: "",
          },
          {
            id: 44,
            label: "menuitems.forms.list.wizard",
            link: "",
          },
          {
            id: 45,
            label: "menuitems.forms.list.mask",
            link: "",
          },
        ],
      },
      {
        id: 46,
        label: "menuitems.tables.text",
        subItems: [
          {
            id: 47,
            label: "menuitems.tables.list.basic",
            link: "",
          },
          {
            id: 48,
            label: "menuitems.tables.list.advanced",
            link: "",
          },
        ],
      },
      {
        id: 50,
        label: "menuitems.charts.text",
        subItems: [
          {
            id: 51,
            label: "menuitems.charts.list.apex",
            link: "",
          },
          {
            id: 52,
            label: "menuitems.charts.list.chartjs",
            link: "",
          },
          {
            id: 52,
            label: "menuitems.charts.list.chartist",
            link: "",
          },
          {
            id: 52,
            label: "menuitems.charts.list.echart",
            link: "",
          },
        ],
      },
      {
        id: 53,
        label: "menuitems.icons.text",
        subItems: [
          {
            id: 54,
            label: "menuitems.icons.list.remix",
            link: "",
          },
          {
            id: 55,
            label: "menuitems.icons.list.materialdesign",
            link: "",
          },
          {
            id: 56,
            label: "menuitems.icons.list.dripicons",
            link: "",
          },
          {
            id: 57,
            label: "menuitems.icons.list.fontawesome",
            link: "",
          },
        ],
      },
      {
        id: 58,
        label: "menuitems.maps.text",
        subItems: [
          {
            id: 59,
            label: "menuitems.maps.list.googlemap",
            link: "",
          },
          {
            id: 60,
            label: "menuitems.maps.list.leaflet",
            link: "",
          },
        ],
      },
    ],
  },
  {
    id: 61,
    label: "menuitems.pages.text",
    icon: "ri-file-copy-2-line",
    subItems: [
      {
        id: 62,
        label: "menuitems.authentication.text",
        subItems: [
          {
            id: 63,
            label: "menuitems.authentication.list.login",
            link: "",
          },
          {
            id: 64,
            label: "menuitems.authentication.list.register",
            link: "",
          },
          {
            id: 65,
            label: "menuitems.authentication.list.recoverpwd",
            link: "",
          },
          {
            id: 66,
            label: "menuitems.authentication.list.lockscreen",
            link: "",
          },
        ],
      },
      {
        id: 67,
        label: "menuitems.utility.text",
        subItems: [
          {
            id: 68,
            label: "menuitems.utility.list.starter",
            link: "",
          },
          {
            id: 69,
            label: "menuitems.utility.list.maintenance",
            link: "",
          },
          {
            id: 70,
            label: "menuitems.utility.list.comingsoon",
            link: "",
          },
          {
            id: 71,
            label: "menuitems.utility.list.timeline",
            link: "",
          },
          {
            id: 72,
            label: "menuitems.utility.list.faqs",
            link: "",
          },
          {
            id: 73,
            label: "menuitems.utility.list.pricing",
            link: "",
          },
          {
            id: 74,
            label: "menuitems.utility.list.error404",
            link: "",
          },
          {
            id: 75,
            label: "menuitems.utility.list.error500",
            link: "",
          },
        ],
      },
    ],
  },
];
